import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          {
            title: t('app'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
            roles: ['employee'],
          },
          // {
          //   title: t('dcc'),
          //   path: paths.dashboard.dcc,
          //   icon: ICONS.banking,
          //   roles: ['admin'],
          // },

        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('management'),
        items: [
          {
            title: t('okr'),
            path: paths.dashboard.okr.root,
            icon: ICONS.blog,
            roles: ['hr', 'okr'],
          },
          // PASTA 義起吃麵(先移除)
          // {
          //   title: t('pasta'),
          //   path: paths.dashboard.pasta.root,
          //   icon: ICONS.order,
          //   roles: ['pasta'],
          // },

          // 公司訂餐的系統，暫時先移除。
          // {
          //   title: t('meal'),
          //   path: paths.dashboard.meal.root,
          //   icon: ICONS.ecommerce,
          //   roles: ['admin'],
          // },
          {
            title: t('quotation_system'),
            path: paths.dashboard.estimate.root,
            icon: ICONS.product,
            roles: ['admin', 'estimate'],
            children: [
              { title: t('estimate_new'), path: paths.dashboard.estimate.new },
              { title: t('estimate_list'), path: paths.dashboard.estimate.list },
              
              { title: t('quotation_new'), path: paths.dashboard.estimate.quotation.new },
              // { title: t('quotation_list'), path: paths.dashboard.estimate.quotation.list },  

              { title: t('workorder_new'), path: paths.dashboard.estimate.workorder.new },
              { title: t('workorder_list'), path: paths.dashboard.estimate.workorder.list },  
            ],
          },

          // {
          //   title: t('workorder_system'),
          //   path: paths.dashboard.workorder.root,
          //   icon: ICONS.product,
          //   roles: ['admin', 'estimate'],
          //   children: [
          //     { title: t('workorder_new'), path: paths.dashboard.workorder.new },
          //     { title: t('workorder_list'), path: paths.dashboard.workorder.list },   

          //   ],
          // },

          // 先把估價單做好後，再來做報價單
          // {
          //   title: t('quotation_system'),
          //   path: paths.dashboard.quotation.root,
          //   icon: ICONS.ecommerce,
          //   roles: ['admin'],
          // },
          // USER
          {
            title: t('employee'),
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            roles: ['employee'],
          },
          // 公司休假狀態月曆
          {
            title: t('leaves'),
            path: paths.dashboard.leaves,
            icon: ICONS.tour,
            roles: ['employee'],
          },
          {
            title: t('attendance'),
            path: paths.dashboard.attendance.root,
            icon: ICONS.file,
            roles: ['employee'],
            children: [
              { title: t('leave_new'), path: paths.dashboard.attendance.leave.new },
              { title: t('timesheet_new'), path: paths.dashboard.attendance.timesheet.new },
              { title: t('overtime_new'), path: paths.dashboard.attendance.overtime.new },
              { title: t('leave_list'), path: paths.dashboard.attendance.leave.list },
              { title: t('timesheet_list'), path: paths.dashboard.attendance.timesheet.list },
              { title: t('overtime_list'), path: paths.dashboard.attendance.overtime.list },
              { title: t('clock_list'), path: paths.dashboard.attendance.clock.list },
            ],
          },
          {
            title: t('attendance_review'),
            path: paths.dashboard.attendanceReview.root,
            icon: ICONS.lock,
            roles: ['manager'],
            children: [
              {
                title: t('attendance_review_leaves'),
                path: paths.dashboard.attendanceReview.leave.review,
              },
              {
                title: t('attendance_review_timesheet'),
                path: paths.dashboard.attendanceReview.timesheet.review,
              },
              {
                title: t('attendance_review_overtime'),
                path: paths.dashboard.attendanceReview.overtime.review,
              },
              {
                title: t('attendance_review_clock'),
                path: paths.dashboard.attendanceReview.clock.review,
              },
              {
                title: t('attendance_review_annual'),
                path: paths.dashboard.attendanceReview.annual.review,
              },
            ],
          },
          {
            title: t('hrsystem'),
            path: paths.dashboard.hrsystem.root,
            icon: ICONS.kanban,
            roles: ['hr'],
            children: [
              { title: t('leave_list'), path: paths.dashboard.hrsystem.leave.list },
              { title: t('timesheet_list'), path: paths.dashboard.hrsystem.timesheet.list },
              { title: t('overtime_list'), path: paths.dashboard.hrsystem.overtime.list },
              { title: t('hrsystem_clock_list'), path: paths.dashboard.hrsystem.clock.list },
              {
                title: t('hrsystem_annual_leave_list'),
                path: paths.dashboard.hrsystem.annual.list,
              },
            ],
          },
          // 會議室預約系統
          {
            title: t('rooms'),
            path: paths.dashboard.rooms.root,
            icon: ICONS.calendar,
            roles: ['employee'],
            children: [
              { title: t('rooms_calendar'), path: paths.dashboard.rooms.calendar },
              {
                title: t('rooms_minute'),
                path: paths.dashboard.rooms.minute.root,
              },
            ],
          },
          // 系統管理
          {
            title: t('admin_panel'),
            path: paths.dashboard.adminPanel.root,
            icon: ICONS.label,
            roles: ['admin', 'hr'],
            children: [
              { title: t('admin_mainpage'), path: paths.dashboard.adminPanel.mainpage },
              { title: t('admin_employee'), path: paths.dashboard.adminPanel.employee.list },
              { title: t('admin_specialdays'), path: paths.dashboard.adminPanel.specialdays.list },
              { title: t('admin_departments'), path: paths.dashboard.adminPanel.departments.list },
            ],
          },
        ],
      },

      {
        subheader: t('websites'),
        items: [
          {
            title: t('壹群實業'),
            path: 'https://yiqun17.com/',
            icon: ICONS.external,
            roles: ['employee'],
          },
          {
            title: t('清水茶香'),
            path: 'https://www.tttp1994.com/',
            icon: ICONS.external,
            roles: ['employee'],
          },
          {
            title: t('研堂典包裝'),
            path: 'https://ytddesign.com/',
            icon: ICONS.external,
            roles: ['employee'],
          },
          {
            title: t('橙京食品'),
            path: 'https://changingfood.com.tw/',
            icon: ICONS.external,
            roles: ['employee'],
          },
          {
            title: t('Minimal Documents'),
            path: 'https://docs.minimals.cc/introduction',
            icon: ICONS.external,
            roles: ['admin'],
          },
          {
            title: t('Minimal Components'),
            path: 'https://minimals.cc/components',
            icon: ICONS.external,
            roles: ['admin'],
          },
          {
            title: t('Material UI Components'),
            path: 'https://mui.com/material-ui/react-table/',
            icon: ICONS.external,
            roles: ['admin'],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
