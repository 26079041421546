import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { element } from 'prop-types';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// DCC
const DCCPage = lazy(() => import('src/pages/dashboard/dcc'));

const PastaListPage = lazy(() => import('src/pages/dashboard/pasta/list'));

// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));

// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));

// APP
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// attendance
const LeaveListPage = lazy(() => import('src/pages/dashboard/attendance/leave-list'));
const LeaveCreatePage = lazy(() => import('src/pages/dashboard/attendance/leave-new'));
const LeaveEditPage = lazy(() => import('src/pages/dashboard/attendance/leave-edit'));
const LeaveReviewListPage = lazy(() => import('src/pages/dashboard/attendance/leave-review-list'));

const ClockListPage = lazy(() => import('src/pages/dashboard/attendance/clock-list'));
const ClockReviewListPage = lazy(() => import('src/pages/dashboard/attendance/clock-review-list'));

const TimesheetCreatePage = lazy(() => import('src/pages/dashboard/attendance/timesheet-new'));
const TimesheetListPage = lazy(() => import('src/pages/dashboard/attendance/timesheet-list'));
const TimesheetEditPage = lazy(() => import('src/pages/dashboard/attendance/timesheet-edit'));
const TimesheetReviewListPage = lazy(() =>
  import('src/pages/dashboard/attendance/timesheet-review-list')
);

const OvertimeCreatePage = lazy(() => import('src/pages/dashboard/attendance/overtime-new'));
const OvertimeListPage = lazy(() => import('src/pages/dashboard/attendance/overtime-list'));
const OvertimeEditPage = lazy(() => import('src/pages/dashboard/attendance/overtime-edit'));
const OvertimeReviewListPage = lazy(() =>
  import('src/pages/dashboard/attendance/overtime-review-list')
);

const AnnualReviewListPage = lazy(() =>
  import('src/pages/dashboard/attendance/annual-review-list')
);

const HrSystemMainPage = lazy(() => import('src/pages/dashboard/hrsystem/main'));
const HrSystemClockListPage = lazy(() => import('src/pages/dashboard/hrsystem/clock-list'));
const HrSystemLeaveListPage = lazy(() => import('src/pages/dashboard/hrsystem/leave-list'));
const HrSystemLeaveCreatePage = lazy(() => import('src/pages/dashboard/hrsystem/leave-new'));
const HrSystemTimesheetListPage = lazy(() => import('src/pages/dashboard/hrsystem/timesheet-list'));
const HrSystemTimesheetCreatePage = lazy(() =>
  import('src/pages/dashboard/hrsystem/timesheet-new')
);
const HrSystemOvertimeListPage = lazy(() => import('src/pages/dashboard/hrsystem/overtime-list'));
const HrSystemOvertimeCreatePage = lazy(() => import('src/pages/dashboard/hrsystem/overtime-new'));
const HrSystemAnnualListPage = lazy(() => import('src/pages/dashboard/hrsystem/annual-list'));

const RoomsCalendarPage = lazy(() => import('src/pages/dashboard/rooms/calendar'));
const RoomsMinuteListPage = lazy(() => import('src/pages/dashboard/rooms/minute-list'));
const RoomsMinuteCreatePage = lazy(() => import('src/pages/dashboard/rooms/minute-new'));
const RoomsMinuteEditPage = lazy(() => import('src/pages/dashboard/rooms/minute-edit'));
const RoomsMinuteDetailsPage = lazy(() => import('src/pages/dashboard/rooms/minute-details'));

const LeavesCalendarPage = lazy(() => import('src/pages/dashboard/leaves'));

const AdminMainPage = lazy(() => import('src/pages/dashboard/admin/mainpage'));

const EmployeeCreatePage = lazy(() => import('src/pages/dashboard/admin/employee-new'));
const EmployeeEditPage = lazy(() => import('src/pages/dashboard/admin/employee-edit'));
const EmployeeListPage = lazy(() => import('src/pages/dashboard/admin/employee-list'));

const SpecialDaysListPage = lazy(() => import('src/pages/dashboard/admin/specialdays-list'));
const DepartmentListPage = lazy(() => import('src/pages/dashboard/admin/department-list'));
const DepartmentCreatePage = lazy(() => import('src/pages/dashboard/admin/department-new'));
const DepartmentEditPage = lazy(() => import('src/pages/dashboard/admin/department-edit'));

const MealCardsPage = lazy(() => import('src/pages/dashboard/meal/cards'));

const OkrListPage = lazy(() => import('src/pages/dashboard/okr/list'));
const OkrCreatePage = lazy(() => import('src/pages/dashboard/okr/new'));
const OkrEditPage = lazy(() => import('src/pages/dashboard/okr/edit'));
const OkrDetailsPage = lazy(() => import('src/pages/dashboard/okr/detail'));

const EstimateListPage = lazy(() => import('src/pages/dashboard/estimate/list'));
const EstimateCreatePage = lazy(() => import('src/pages/dashboard/estimate/new'));
const EstimateEditPage = lazy(() => import('src/pages/dashboard/estimate/edit'));
const EstimateDetailsPage = lazy(() => import('src/pages/dashboard/estimate/detail'));

const QuotationCreatePage = lazy(() => import('src/pages/dashboard/quotation/new'));
const QuotationListPage = lazy(() => import('src/pages/dashboard/quotation/list'));
const QuotationEditPage = lazy(() => import('src/pages/dashboard/quotation/edit'));

const WorkorderListPage = lazy(() => import('src/pages/dashboard/workorder/list'));
const WorkorderCreatePage = lazy(() => import('src/pages/dashboard/workorder/new'));
const WorkorderEditPage = lazy(() => import('src/pages/dashboard/workorder/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'dcc',
        children: [
          { element: <DCCPage />, index: true },
          { path: 'list', element: <DCCPage /> },
        ],
      },
      {
        path: 'okr',
        children: [
          { element: <OkrListPage />, index: true },
          { path: 'list', element: <OkrListPage /> },
          { path: 'new', element: <OkrCreatePage /> },
          { path: ':id/edit', element: <OkrEditPage /> },
          { path: ':id', element: <OkrDetailsPage /> },
        ],
      },
      {
        path: 'pasta',
        children: [
          { element: <PastaListPage />, index: true },
          { path: 'list', element: <PastaListPage /> },
        ],
      },
      {
        path: 'meal',
        children: [
          { element: <MealCardsPage />, index: true },
          { path: 'cards', element: <MealCardsPage /> },
        ],
      },
      {
        path: 'estimate',
        children: [
          { element: <EstimateListPage />, index: true },
          { path: 'list', element: <EstimateListPage /> },
          { path: 'new', element: <EstimateCreatePage /> },
          { path: ':id/edit', element: <EstimateEditPage /> },
          { path: ':id', element: <EstimateDetailsPage /> },

          { path: 'quotation/list', element: <QuotationListPage /> },
          { path: 'quotation/new', element: <QuotationCreatePage /> },
          { path: 'quotation/:id/edit', element: <QuotationEditPage /> },
          
          { path: 'workorder/list', element: <WorkorderListPage /> },
          { path: 'workorder/new', element: <WorkorderCreatePage /> },
          { path: 'workorder/:id/edit', element: <WorkorderEditPage /> },
        ]
      },
      // {
      //   path: 'workorder',
      //   children: [
      //     { element: <WorkorderListPage />, index: true },
      //     { path: 'list', element: <WorkorderListPage /> },
      //     { path: 'new', element: <WorkorderCreatePage /> },
      //     // { path: ':id/edit', element: <WorkorderEditPage /> },
      //     // { path: ':id', element: <EstimateDetailsPage /> },
      //   ]
      // },
      {
        path: 'quotation',
        children: [
          { element: <EstimateListPage />, index: true },
          { path: 'list', element: <EstimateListPage /> },
          { path: 'new', element: <QuotationCreatePage /> },
          { path: ':id/edit', element: <EstimateEditPage /> },
          { path: ':id', element: <EstimateDetailsPage /> },
        ]
      },
      {
        path: 'user',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'list', element: <UserListPage /> },
          { path: ':id/edit', element: <UserEditPage /> },
        ],
      },
      {
        path: 'hrsystem', // "人資系統"
        children: [
          { element: <HrSystemMainPage />, index: true },
          { path: 'clock/list', element: <HrSystemClockListPage /> },
          { path: 'leave/list', element: <HrSystemLeaveListPage /> },
          { path: 'leave/new', element: <HrSystemLeaveCreatePage /> },
          { path: 'timesheet/list', element: <HrSystemTimesheetListPage /> },
          { path: 'timesheet/new', element: <HrSystemTimesheetCreatePage /> },
          { path: 'overtime/list', element: <HrSystemOvertimeListPage /> },
          { path: 'overtime/new', element: <HrSystemOvertimeCreatePage /> },
          { path: 'annual/list', element: <HrSystemAnnualListPage /> },
        ],
      },
      {
        path: 'attendance', // "差勤系統"（Attendance Management）是人力資源管理的一個重要環節，主要涉及到員工出勤、缺勤、遲到、早退、請假、加班等方面的記錄和管理。
        children: [
          { element: <LeaveListPage />, index: true },
          { path: 'leave/list', element: <LeaveListPage /> },
          { path: 'leave/new', element: <LeaveCreatePage /> },
          { path: 'leave/:id/edit', element: <LeaveEditPage /> },

          { path: 'timesheet/list', element: <TimesheetListPage /> },
          { path: 'timesheet/:id/edit', element: <TimesheetEditPage /> },
          { path: 'timesheet/new', element: <TimesheetCreatePage /> },

          { path: 'overtime/list', element: <OvertimeListPage /> },
          { path: 'overtime/:id/edit', element: <OvertimeEditPage /> },
          { path: 'overtime/new', element: <OvertimeCreatePage /> },

          { path: 'clock/list', element: <ClockListPage /> },
        ],
      },
      {
        path: 'attendancereview', // "差勤系統"（Attendance Management）是人力資源管理的一個重要環節，主要涉及到員工出勤、缺勤、遲到、早退、請假、加班等方面的記錄和管理。
        children: [
          { element: <LeaveReviewListPage />, index: true },
          { path: 'leave/list', element: <LeaveReviewListPage /> },
          { path: 'timesheet/list', element: <TimesheetReviewListPage /> },
          { path: 'overtime/list', element: <OvertimeReviewListPage /> },
          { path: 'clock/list', element: <ClockReviewListPage /> },
          { path: 'annual/list', element: <AnnualReviewListPage /> },
        ],
      },
      {
        path: 'rooms',
        children: [
          { element: <RoomsCalendarPage />, index: true },
          { path: 'calendar', element: <RoomsCalendarPage /> },
          {
            path: 'minute',
            children: [
              { element: <RoomsMinuteListPage />, index: true },
              { path: 'list', element: <RoomsMinuteListPage /> },
              { path: ':id', element: <RoomsMinuteDetailsPage /> },
              { path: ':id/edit', element: <RoomsMinuteEditPage /> },
              { path: 'new', element: <RoomsMinuteCreatePage /> },
            ],
          },
        ],
      },
      { path: 'leaves', element: <LeavesCalendarPage /> },
      {
        path: 'admin',
        children: [
          { path: 'mainpage', element: <AdminMainPage /> },
          {
            path: 'employee',
            children: [
              { element: <EmployeeListPage />, index: true },
              { path: 'new', element: <EmployeeCreatePage /> },
              { path: 'list', element: <EmployeeListPage /> },
              { path: ':id/edit', element: <EmployeeEditPage /> },
            ],
          },
          {
            path: 'specialdays',
            children: [
              { element: <SpecialDaysListPage />, index: true },
              { path: 'list', element: <SpecialDaysListPage /> },
            ],
          },
          {
            path: 'departments',
            children: [
              { element: <DepartmentListPage />, index: true },
              { path: 'list', element: <DepartmentListPage /> },
              { path: 'new', element: <DepartmentCreatePage /> },
              { path: ':id/edit', element: <DepartmentEditPage /> },
            ],
          },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
